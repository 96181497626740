import React, { useState, useEffect, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Select,
  MenuItem,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import DoneIcon from "@material-ui/icons/Done"
import { Container, Row, Button } from "shards-react"
import { withStyles } from "@material-ui/core/styles"
import { AgGridReact } from "ag-grid-react"
import { startCase, isEmpty } from "lodash"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { useSelector } from "react-redux"
import PageTitle from "../../../components/common/PageTitle"
import { LoadingView } from "../../../views"
import { useDispatch } from "react-redux"
import { ApplicationTableRowsActions } from "../components/ApplicationTableRowsActions"
import { surveyActions } from "../../surveys"
import { reportsActions } from "../../reports"
import { applicationActions } from "../_actions"
import { modalActions } from "../../modals"
import { FilterApplicationQuestionnaire } from "../components/filterQuestionnaire"
import "./Members.scss"
import styles from "./ListPayment.styles"

export const Collection = withStyles(styles)(({ classes }) => {
  const authentication = useSelector((state) => state.authentication)
  const applications = useSelector((state) => state.applications)
  const reports = useSelector((state) => state.reports)
  const { loading, usersToAssign } = applications
  const { downloading } = reports
  const {
    profile: { username, roles },
  } = authentication

  const [collectionData, setCollectionData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState("")

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onSelectionChanged = useCallback((event) => {
    setSelectedRows(event.api.getSelectedRows())
  }, [])

  const approvalUsers = !isEmpty(usersToAssign)
    ? usersToAssign.forward.map((user) => user.username)
    : []

  useEffect(() => {
    if (username) dispatch(applicationActions.fetchUsersToAssign(username))
  }, [dispatch, username])

  const onBulkSubmit = () => {
    const payload = selectedRows.map((row) => ({
      farmerCode: row["NRC No"],
      transactionAmount: Number(row["Repayment Amount"]),
      mfiName: "AMZ",
      clientName: row["Client Name"],
      id: row.id,
    }))
    dispatch(
      modalActions.approvePayCollection({
        payload,
        selectedQuestionnaire,
      })
    )
  }

  const isRowSelectable = (node) => {
    // Only rows where statis is pending are selectable
    return node.data.Status === "Pending"
  }

  useEffect(() => {
    setCollectionData(
      applications.items
        ?.filter(
          (item) =>
            approvalUsers.includes(item.currentUser) &&
            (applications.filterState.group.length === 0 ||
              applications.filterState.group.includes(item.json.Group))
        )
        .map((item) => ({
          id: item.id,
          subRoleStageFinal: item.subRoleStageFinal,
          workflowStatus: item.workflowStatus,
          ...item.json,
          Status: item.json.Status === "0" ? "Paid" : "Pending",
        }))
    )
  }, [applications])

  useEffect(() => {
    if (!selectedQuestionnaire) setCollectionData([])
  }, [selectedQuestionnaire])

  const SelectRenderer = ({ value, data, api }) => {
    const handleSelectChange = (event) => {
      const newValue = event.target.value
      if (newValue === "repay") {
        const payload = [
          {
            farmerCode: data["NRC No"],
            transactionAmount: Number(data["Repayment Amount"]),
            mfiName: "AMZ",
            clientName: data["Client Name"],
            id: data.id,
          },
        ]
        dispatch(
          modalActions.approvePayCollection({
            payload,
            selectedQuestionnaire,
          })
        )
      }
    }
    const { subRoleStageFinal, id, workflowStatus, Status } = data
    if (!subRoleStageFinal) {
      return (
        <ApplicationTableRowsActions
          id={id}
          status={workflowStatus}
          fromAppDetails
          hasWorkflow
          suppressDetails
        />
      )
    }

    if (Status === "Paid") {
      return <Typography className="actioned-text">Paid</Typography>
    }

    return (
      <Select
        disableUnderline
        defaultValue="action"
        onChange={handleSelectChange}
      >
        <MenuItem value="action">Action</MenuItem>
        {subRoleStageFinal && (
          <MenuItem value="repay">
            Repay <DoneIcon className="done-icon" />
          </MenuItem>
        )}
      </Select>
    )
  }

  const excludeColumns = ["workflowStatus", "subRoleStageFinal"]
  const columnDefs = [
    {
      headerName: "",
      field: "",
      headerCheckboxSelection: applications.filterState.group.length > 0, // show header checkbox only if a group is selected
      checkboxSelection: (params) =>
        applications.filterState.group.length > 0 &&
        params.data.Status === "Pending", // show on rows only if status === Pending
      width: 50,
    },
  ]
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
    }),
    []
  )
  collectionData &&
    collectionData.length > 0 &&
    Object.keys(collectionData[0]).forEach((key) => {
      if (!excludeColumns.includes(key)) {
        columnDefs.push({
          headerName: startCase(key),
          field: key,
        })
      }
    })
  columnDefs.push({
    field: "actions",
    width: 150,
    pinned: "right",
    cellRenderer: SelectRenderer,
    cellStyle: (params) => ({ display: "flex", alignItems: "center" }),
    resizable: false,
    sortable: false,
    filter: false,
  })

  useEffect(() => {
    if (authentication.profile.mfi) {
      dispatch(surveyActions.getSurveys(authentication.profile.mfi.id))
    }
  }, [authentication.profile])

  useEffect(() => {
    dispatch(
      applicationActions.getApplicationBySurveyUuid(selectedQuestionnaire)
    )
  }, [selectedQuestionnaire])

  useEffect(() => {
    selectedQuestionnaire &&
      dispatch(applicationActions.getApplicationColumns(selectedQuestionnaire))
  }, [selectedQuestionnaire, dispatch])

  const selectQuestionnaire = (e) => {
    const { value } = e.target
    setSelectedQuestionnaire(value)
  }

  const handleDownloadCollection = () => {
    dispatch(reportsActions.downloadMusoniReport(username))
  }

  return (
    <Container maxWidth="xl" fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-4 flex">
        <PageTitle sm="4" title={"Collections"} className="text-sm-left" />
      </Row>
      <Row noGutters className="page-header pt-4 flex">
        <FilterApplicationQuestionnaire
          handler={selectQuestionnaire}
          selectedValue={selectedQuestionnaire}
          collectionView
        />
        <div className="application__actions ml-auto flex content-center">
          <Button
            theme="secondary"
            className="mx-2 d-table ml-auto btn btn-filter"
            onClick={() =>
              dispatch(modalActions.officeFilter({ isGroupFilter: true }))
            }
          >
            {t("module.applications.filterByGroup")}
            {applications.filterState.group &&
              applications.filterState.group.length > 0 &&
              `: ${applications.filterState.group.join()}`}
          </Button>
          {roles?.includes("MFI_LOAN_OFFICER") && (
            <Button
              onClick={handleDownloadCollection}
              className="ml-2"
              theme={downloading ? "secondary" : "primary"}
              disabled={downloading}
            >
              {downloading ? "Downloading..." : "Download Collection Report"}
              {downloading && (
                <CircularProgress
                  color="secondary"
                  className="ml-1"
                  size={15}
                />
              )}
            </Button>
          )}
        </div>
      </Row>
      {loading ? (
        <LoadingView />
      ) : (
        <>
          <div
            className="ag-theme-alpine"
            style={{ height: "600px", width: "100%", marginTop: "20px" }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={collectionData || []}
              pagination={true}
              paginationAutoPageSize={true}
              suppressRowClickSelection={true}
              stopEditingWhenGridLosesFocus={true}
              onSelectionChanged={onSelectionChanged}
              defaultColDef={defaultColDef}
              isRowSelectable={isRowSelectable}
            />
          </div>
          {applications.filterState.group.length > 0 && (
            <div className="mt-2 justify-between flex items-center">
              <Button
                className="ml-auto"
                onClick={onBulkSubmit}
                disabled={selectedRows.length === 0}
              >
                Send Bulk Repayments
              </Button>
            </div>
          )}
        </>
      )}
    </Container>
  )
})

export default Collection
