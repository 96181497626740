import React from "react"
import ReactApexChart from "react-apexcharts"
import formatNumber from "../../../utils/FormatNumber"

// PieChart Component
const PieChart = ({ data, labels, colors }) => {
  const counts = labels.map((label) =>
    (
      (data[label] /
        Object.values(data).reduce((sum, count) => sum + count, 0)) *
      100
    ).toFixed(2)
  )

  return (
    <ReactApexChart
      type="pie"
      series={counts.map(Number)}
      options={{
        labels,
        colors,
        tooltip: {
          enabled: true,
          y: {
            formatter: (value, { seriesIndex }) => {
              const count = Object.values(data)[seriesIndex]
              return `${count} (${value.toFixed(2)}%)`
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: { colors: ["white"], fontSize: "14px" },
        },
      }}
    />
  )
}

// BarChart Component with optional horizontal layout
const BarChart = ({ data, title, color, horizontal = false }) => {
  return (
    <ReactApexChart
      type="bar"
      series={[{ name: title, data: Object.values(data) }]}
      options={{
        chart: { type: "bar", toolbar: { show: false } },
        plotOptions: {
          bar: {
            horizontal,
            barHeight: horizontal ? "70%" : "auto",
          },
        },
        xaxis: {
          categories: Object.keys(data),
          labels: {
            formatter: (value) => formatLargeNumber(value), // Format large numbers
            style: { colors: "black", fontSize: "14px" },
          },
          axisBorder: { show: true },
          axisTicks: { show: true },
        },
        yaxis: horizontal
          ? {
              labels: {
                style: { colors: "black", fontSize: "12px" },
                maxWidth: 100,
                formatter: (value) =>
                  value.length > 10 ? value.substring(0, 10) + "..." : value, // Trim long labels
              },
            }
          : {
              labels: {
                formatter: (value) => formatLargeNumber(value), // Apply formatLargeNumber here
                style: { colors: "black", fontSize: "14px" },
              },
            },

        colors: [color],
        tooltip: {
          enabled: true,
          shared: true,
          intersect: false,
          x: { show: true, formatter: (value) => value },
          y: {
            formatter: (value) => formatNumber(value),
          },
        },
        dataLabels: {
          enabled: false,
          style: { colors: ["black"], fontSize: "16px" },
          offsetX: horizontal ? 5 : 0,
        },
        grid: { show: false },
      }}
    />
  )
}
const formatLargeNumber = (num) => {
  if (num >= 1e9) return (num / 1e9).toFixed(1) + "B"
  if (num >= 1e6) return (num / 1e6).toFixed(1) + "M"
  if (num >= 1e3) return (num / 1e3).toFixed(1) + "K"
  return num
}

// Main financialCharts component
const FinancialCharts = {
  renderWorkflowStatusChart(data) {
    if (!data) return null

    const statuses = Object.keys(data)
    const statusColors = {
      Declined: "#FF0000",
      Approved: "#ADD8E6",
      Review: "#008d6a",
      Disbursed: "#3650E6",
      Refused: "#800080",
    }

    const colors = statuses.map((status) => statusColors[status] || "gray")

    return (
      <div>
        <PieChart data={data} labels={statuses} colors={colors} />
      </div>
    )
  },

  renderLoansValuePerStaffChart(data) {
    if (!data) return null

    return (
      <div>
        <BarChart
          data={data}
          title="Loan Value per Staff"
          color="#008d6a"
          horizontal={true}
        />
      </div>
    )
  },

  renderLoanValuesPerBranchChart(data) {
    if (!data) return null

    return (
      <div>
        <BarChart data={data} title="Loan Value per Branch" color="#008d6a" />
      </div>
    )
  },

  renderLoansPerBranchChart(data) {
    if (!data) return null

    return (
      <div>
        <BarChart data={data} title="Loans per Branch" color="#3650E6" />
      </div>
    )
  },

  renderLoansPerStaffChart(data) {
    if (!data) return null

    return (
      <div>
        <BarChart
          data={data}
          title="Loans per Staff"
          color="#3650E6"
          horizontal={true}
        />
      </div>
    )
  },
}

export default FinancialCharts
