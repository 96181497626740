import React from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Segment, Label } from "semantic-ui-react"
import BaseTable, { AutoResizer } from "react-base-table"
import { Button } from "@material-ui/core"
import "react-base-table/styles.css"
import "./BasicTable.scss"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { modalActions } from "../../modules/modals"

const LabelOverlay = () => <Label content="Loading..." color="blue" />

const BasicTable = (props) => {
  const { windowHeight } = useWindowDimensions()
  const dispatch = useDispatch()

  const {
    children,
    rows = [],
    headerHeight = 55,
    rowHeight = 46,
    maxHeight = windowHeight * 0.8,
    loading = false,
    segment = {
      basic: true,
      size: "mini",
      textAlign: "center",
      style: {
        padding: 0,
        marginBottom: "25px",
        width: "100%",
        height: maxHeight,
      },
    },
    group,
    selectedRows,
    showMultipleDisburseActions,
    showTenorAdjustActions,
    ...rest
  } = props

  const disburseApplications = () => {
    const { data } = rest
    dispatch(
      modalActions.disburse({
        disburseAction: true,
        applicationIds: selectedRows.filter((id) =>
          data.some((row) => row.id === id)
        ),
        multiple: true,
      })
    )
  }

  const adjustLoanTenorsAndRecalculate = () => {
    const applicationIds = selectedRows.map((id) => id)
    dispatch(modalActions.recalculateScores({ applicationIds, group }))
  }

  return (
    <Segment {...segment}>
      <AutoResizer>
        {({ width, height }) => (
          <>
            <BaseTable
              className={`table ${props.className}`}
              data={rows}
              width={width}
              height={height}
              headerHeight={headerHeight}
              rowHeight={rowHeight}
              maxHeight={maxHeight}
              overlayRenderer={loading ? LabelOverlay : null}
              {...rest}
            >
              {children}
            </BaseTable>
            {showMultipleDisburseActions && (
              <Button
                className="disburse-button"
                color="primary"
                variant="contained"
                disabled={selectedRows.length === 0}
                onClick={disburseApplications}
              >
                Disburse Selected Applications
              </Button>
            )}
            {showTenorAdjustActions && (
              <Button
                className="disburse-button"
                color="primary"
                variant="contained"
                disabled={selectedRows.length === 0}
                onClick={adjustLoanTenorsAndRecalculate}
              >
                Adjust Applications Tenor/Grace Period
              </Button>
            )}
          </>
        )}
      </AutoResizer>
    </Segment>
  )
}

BasicTable.propTypes = {
  children: PropTypes.any,
  maxHeight: PropTypes.number,
  loading: PropTypes.bool,
  segment: PropTypes.object,
  rows: PropTypes.array,
}

export default BasicTable
