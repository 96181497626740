import React, { useState, useCallback, useEffect } from "react"
import {
  Select,
  MenuItem,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Container, Row, Button } from "shards-react"
import { withStyles } from "@material-ui/core/styles"
import { AgGridReact } from "ag-grid-react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import cx from "classnames"
import { startCase, isEmpty } from "lodash"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { useSelector, useDispatch } from "react-redux"
import PageTitle from "../../../components/common/PageTitle"
import { CSVLink } from "react-csv"
import { LoadingView } from "../../../views"
import { publisherActions } from "../../publisher"
import { ApplicationTableRowsActions } from "../components/ApplicationTableRowsActions"
import styles from "./ListPayment.styles"
import { alertActions } from "../../alert"
import { applicationActions } from "../_actions"
import { ImageCellRenderer } from "./ImageCellRenderer"
import "./Members.scss"

export const MembersApplication = withStyles(styles)(
  ({ classes, location }) => {
    const { loading } = useSelector((state) => state.publishers)
    const {
      villageBanks = [],
      entityStatusList = {},
      loading: entityStatusLoading,
      usersToAssign,
    } = useSelector((state) => state.applications)
    const [selectedRows, setSelectedRows] = useState([])
    const { profile } = useSelector((state) => state.authentication)
    const [selectedVillageBank, setSelectedVillageBank] = useState(null)

    const approvalUsers = !isEmpty(usersToAssign)
      ? usersToAssign.forward.map((user) => user.username)
      : []

    const {
      state: {
        groupGeneralInfo,
        groupMembersInfo,
        groupID,
        workflowStatus,
        subRoleStageFinal,
        stage,
      },
    } = location

    const { groupName, clientOfficer } = groupGeneralInfo

    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {
      if (entityStatusList?.vbName && villageBanks.length > 0) {
        const villageBank = villageBanks.find(
          (vb) => vb.name === entityStatusList?.vbName
        )
        setSelectedVillageBank(villageBank !== undefined ? villageBank : null)
      }
    }, [entityStatusList?.vbName, villageBanks])

    useEffect(() => {
      if (!subRoleStageFinal) {
        dispatch(applicationActions.fetchUsersToAssign(groupID))
      }
    }, [dispatch, groupID])

    useEffect(() => {
      if (villageBanks.length === 0 && profile?.hierarchyEntity) {
        dispatch(applicationActions.getVillageBanks(profile.hierarchyEntity))
      }
    }, [dispatch, villageBanks.length, profile])

    useEffect(() => {
      const externalClientIds = groupMembersInfo?.map((member) => member.nrcNo)
      if (externalClientIds.length > 0) {
        dispatch(applicationActions.fetchEntityStatus(externalClientIds))
      }
    }, [dispatch, groupMembersInfo])

    const allStatuses = groupMembersInfo.map(
      (member) => entityStatusList?.statusData?.[member.nrcNo] || "Pending"
    )

    const isVillageBankSelectionDisabled = allStatuses.some(
      (status) => status !== "Pending"
    )

    const statusRenderer = (params) => {
      const externalId = params.data.nrcNo
      const status = entityStatusList?.statusData?.[externalId] || "Pending"

      return (
        <div className="actions-menu">
          {entityStatusLoading ? <CircularProgress size={24} /> : status}
        </div>
      )
    }

    const viewChecklist = (params) => {
      return (
        <Link to={{ pathname: "/document-checklist", state: { groupID } }}>
          View Checklist
        </Link>
      )
    }

    const columnDefs = [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        width: 40,
        suppressMenu: true,
        pinned: "left",
        field: "select",
        hide: stage === "MFI_LOAN_OFFICER",
      },
    ]

    groupMembersInfo &&
      Object.entries(groupMembersInfo[0]).forEach(([key]) => {
        if (key === "clientImage") {
          columnDefs.push({
            headerName: startCase(key),
            field: key,
            cellRenderer: ImageCellRenderer,
          })
        } else {
          columnDefs.push({
            headerName: startCase(key),
            field: key,
          })
        }
      })

    columnDefs.push({
      headerName: "KYC Document Checklist",
      field: "checklist",
      cellRenderer: viewChecklist,
    })

    columnDefs.push({
      headerName: "Status",
      field: "status",
      cellRenderer: statusRenderer,
      pinned: "right",
    })

    const submitGroup = async () => {
      const payload = selectedRows.map((row, index) => {
        const {
          dob,
          email,
          firstName,
          gender,
          lastName,
          mobileNo,
          nrcNo,
          clientImage,
        } = row

        if (allStatuses[index] === "Active") {
          return {}
        }

        const username = clientOfficer.substring(clientOfficer.indexOf("_") + 1)
        return {
          groupName,
          groupId: groupID,
          firstName,
          lastName,
          farmerId: nrcNo,
          dob,
          mobileNo: mobileNo ? mobileNo : "0700000000",
          email: email ? email : "notavailable@lendxs.com",
          genderId: gender.toLowerCase(),
          officeName: selectedVillageBank ? selectedVillageBank.name : "",
          staffId: username,
          clientImage: clientImage ? clientImage : "",
        }
      })
      const isEmptyObject = (obj) => Object.keys(obj).length === 0
      if (payload.some(isEmptyObject)) {
        dispatch(alertActions.error("Some selected members are active"))
        return
      }

      await dispatch(publisherActions.createGroupClientsMusoni(payload))

      const externalClientIds = groupMembersInfo.map((member) => member.nrcNo)
      dispatch(applicationActions.fetchEntityStatus(externalClientIds))
    }

    const onSelectionChanged = useCallback((event) => {
      const selectedRows = event.api.getSelectedRows()
      setSelectedRows(selectedRows)
    }, [])

    return (
      <Container maxWidth="xl" fluid className="main-content-container px-4">
        <Row noGutters className="page-header pt-4 flex">
          <PageTitle sm="4" title={"Group Members"} className="text-sm-left" />
        </Row>
        <Row noGutters className="page-header pt-4 flex">
          <Typography className="mr-2 name-semibold">Group Name: </Typography>
          <Typography className="mr-3" color="secondary">
            {groupName}
          </Typography>
          <Typography className="mr-2 name-semibold">
            Client Officer:{" "}
          </Typography>
          <Typography color="secondary">{clientOfficer}</Typography>
          <div className="application__actions ml-auto flex content-center">
            {!subRoleStageFinal && (
              <ApplicationTableRowsActions
                id={groupID}
                status={workflowStatus}
                fromAppDetails
                hasWorkflow
                suppressDetails
                approvalUsers={approvalUsers}
              />
            )}

            {subRoleStageFinal && (
              <Autocomplete
                options={villageBanks}
                getOptionLabel={(option) => option.name}
                value={selectedVillageBank}
                onChange={(event, newValue) => setSelectedVillageBank(newValue)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Village Bank"
                    InputProps={{
                      ...params.InputProps,
                      style: { height: "40px" },
                      inputProps: {
                        ...params.inputProps,
                        style: { marginTop: "-7px" },
                      },
                    }}
                    InputLabelProps={{
                      style: { marginTop: "-7px" },
                    }}
                  />
                )}
                style={{ minWidth: "360px", height: "40px" }}
                disabled={isVillageBankSelectionDisabled}
              />
            )}
            <Select
              classes={{ root: classes.selectRoot }}
              variant="outlined"
              value="Download Members"
              className={cx("ml-2", {
                [classes.selectDisabled]: groupMembersInfo.length === 0,
              })}
            >
              <MenuItem value="Download Members">Download Members</MenuItem>

              <CSVLink
                className="hover:no-underline"
                data={groupMembersInfo || []}
                filename={"members.csv"}
                onClick={() => dispatch(alertActions.success("Downloading..."))}
              >
                <MenuItem value="csv">csv</MenuItem>
              </CSVLink>

              <CSVLink
                className="hover:no-underline"
                data={groupMembersInfo || []}
                filename={"members.xlsx"}
                onClick={() => dispatch(alertActions.success("Downloading..."))}
              >
                <MenuItem value="xlsx">xlsx</MenuItem>
              </CSVLink>
            </Select>
          </div>
        </Row>
        {loading ? (
          <LoadingView />
        ) : (
          <>
            <div
              className="ag-theme-alpine"
              style={{ height: "600px", width: "100%", marginTop: "20px" }}
            >
              <AgGridReact
                rowData={groupMembersInfo}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={30}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                stopEditingWhenGridLosesFocus={true}
                onSelectionChanged={onSelectionChanged}
              />
            </div>
            {subRoleStageFinal && (
              <div className="flex justify-end mt-2">
                <Button
                  disabled={!selectedVillageBank || selectedRows.length === 0}
                  onClick={submitGroup}
                >
                  Create Group
                </Button>
              </div>
            )}
          </>
        )}
      </Container>
    )
  }
)

export default MembersApplication
