import React, { useState, useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Container, Row } from "shards-react"
import { AgGridReact } from "ag-grid-react"
import { Link, useLocation } from "react-router-dom"
import { startCase } from "lodash"
import {
  Typography,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core"
import { CSVLink } from "react-csv"
import jsPDF from "jspdf"
import "jspdf-autotable"
import cx from "classnames"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { useSelector, useDispatch } from "react-redux"
import PageTitle from "../../../components/common/PageTitle"
import { alertActions } from "../../alert"
import { applicationActions } from "../_actions"
import styles from "./ListPayment.styles"
import "./Members.scss"

export const GroupMembers = withStyles(styles)(({ classes }) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { state: { groupName = {}, groupMembers = [], groupID } = {} } =
    location || {}

  const { entityStatusList = {}, loading: entityStatusLoading } = useSelector(
    (state) => state.applications
  )

  // Field to exclude
  const fieldToRemove = "clientImage"

  // Remove the field from data
  const filteredData = groupMembers.map(
    ({ [fieldToRemove]: _, ...rest }) => rest
  )

  useEffect(() => {
    const externalClientIds = groupMembers.map((member) => member.nrcNo)
    if (externalClientIds.length > 0) {
      dispatch(applicationActions.fetchEntityStatus(externalClientIds))
    }
  }, [dispatch, groupMembers])

  const statusRenderer = (params) => {
    const externalId = params.data.nrcNo
    const status = entityStatusList?.statusData?.[externalId] || "Pending"

    return (
      <div className="actions-menu">
        {entityStatusLoading ? <CircularProgress size={24} /> : status}
      </div>
    )
  }

  const columnDefs = groupMembers.length
    ? Object.keys(groupMembers[0]).map((key) => {
        if (key === "clientImage") {
          return {}
        } else {
          return {
            headerName: startCase(key),
            field: key,
            sortable: true,
            filter: true,
          }
        }
      })
    : []

  columnDefs.push({
    headerName: "Status",
    field: "status",
    cellRenderer: statusRenderer,
    pinned: "right",
  })

  const generatePDF = () => {
    const doc = new jsPDF({ margin: 5 })

    // Set title to bold and larger font size
    doc.setFont("helvetica", "bold")
    doc.setFontSize(16)
    doc.setTextColor(2, 104, 61)
    doc.text(`${groupName}`, 14, 10)

    // Reset font to normal for table content
    doc.setFont("helvetica", "normal")
    doc.setTextColor(0, 0, 0)
    doc.setFontSize(8)

    // Extract headers dynamically
    const headers = Object.keys(filteredData[0] || {})
    const rows = filteredData.map((obj) => headers.map((header) => obj[header]))

    // Adding table
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 20,
      headStyles: {
        fillColor: [2, 104, 61],
        textColor: 255,
        fontStyle: "bold",
      },
    })

    // Save the PDF
    doc.save(`${groupName} Members.pdf`)
  }

  return (
    <Container maxWidth="xl" fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-4 flex">
        <PageTitle sm="4" title={"Group Members"} className="text-sm-left" />
      </Row>
      <Row noGutters className="page-header pt-4 flex">
        <Typography className="mr-2 name-semibold">Group Name: </Typography>
        <Typography className="mr-3" color="secondary">
          {groupName}
        </Typography>
        <div className="application__actions ml-auto flex content-center">
          <Select
            classes={{ root: classes.selectRoot }}
            variant="outlined"
            value="Download Members"
            className={cx("ml-2", {
              [classes.selectDisabled]: filteredData.length === 0,
            })}
          >
            <MenuItem value="Download Members">Download Members</MenuItem>

            <CSVLink
              className="hover:no-underline"
              data={filteredData || []}
              filename={`${groupName} members.csv`}
              onClick={() => dispatch(alertActions.success("Downloading..."))}
            >
              <MenuItem value="csv">csv</MenuItem>
            </CSVLink>

            <CSVLink
              className="hover:no-underline"
              data={filteredData || []}
              filename={`${groupName} members.xlsx`}
              onClick={() => dispatch(alertActions.success("Downloading..."))}
            >
              <MenuItem value="xlsx">xlsx</MenuItem>
            </CSVLink>
            <MenuItem value="pdf">
              <button onClick={generatePDF}>pdf</button>
            </MenuItem>
          </Select>
        </div>
      </Row>
      <div
        className="ag-theme-alpine"
        style={{ height: "600px", width: "100%", marginTop: "20px" }}
      >
        <AgGridReact
          rowData={groupMembers}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={30}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          stopEditingWhenGridLosesFocus={true}
        />
      </div>
    </Container>
  )
})

export default GroupMembers
