import React, { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Container, Row } from "shards-react"
import { Link } from "react-router-dom"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { applicationActions } from "../_actions"
import { FilterApplicationQuestionnaire } from "../components/filterQuestionnaire"
import { formatDate } from "../../../utils/FormatDate"
import "./Members.scss"
import { camelCase, isEmpty } from "lodash"

export const GroupsMembers = () => {
  const dispatch = useDispatch()
  const authentication = useSelector((state) => state.authentication)
  const applications = useSelector((state) => state.applications)
  const applicationColumns = applications?.applicationColumns || []
  const [aggregatedData, setAggregatedData] = useState([])
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState("")

  const {
    profile: { username },
  } = authentication

  const approvalUsers = !isEmpty(applications?.usersToAssign)
    ? applications.usersToAssign.forward.map((user) => user.username)
    : []

  useEffect(() => {
    if (selectedQuestionnaire) {
      dispatch(
        applicationActions.getApplicationBySurveyUuid(selectedQuestionnaire)
      )
    }
  }, [selectedQuestionnaire, dispatch])
  useEffect(() => {
    selectedQuestionnaire &&
      dispatch(applicationActions.getApplicationColumns(selectedQuestionnaire))
  }, [selectedQuestionnaire, dispatch])

  useEffect(() => {
    if (!applications?.items) return
    const filteredItems = applications.items.filter(
      (item) =>
        (item.currentUser === username && !item.subRoleStageFinal) ||
        approvalUsers.includes(item.currentUser)
    )

    const aggregatedGroups = filteredItems.reduce((acc, item) => {
      const groupID = item.id
      const groupName =
        item?.json?.groupName?.trim() ||
        item?.json?.["GROUP NAME"]?.trim() ||
        item?.json?.["Group Name"]?.trim() ||
        "Unknown Group"

      const rawMembers = Object.entries(item.json)
        .filter(([key, value]) => {
          try {
            return Array.isArray(JSON.parse(value))
          } catch {
            return false
          }
        })
        .map(([key, value]) => JSON.parse(value))
        .flat()

      const formattedMembers = rawMembers.map((raw) => {
        let dateCreated = item.submissionTime
          ? formatDate(new Date(item.submissionTime), "y-m-d")
          : "Unknown Date"

        let individualMember = { dateCreated }

        Object.entries(raw).forEach(([key, value]) => {
          applicationColumns.forEach((column) => {
            if (!column?.questionKey || !column?.columnTitle) return

            const memberKeyParts = column.questionKey.split("/")
            const memberKeyEnd = memberKeyParts[memberKeyParts.length - 1]

            if (key.endsWith(memberKeyEnd)) {
              let formattedValue = value?.trim() || value
              if (formattedValue === "0") formattedValue = "Male"
              else if (formattedValue === "1") formattedValue = "Female"

              individualMember[camelCase(column.columnTitle)] = formattedValue
            }
          })
        })

        return individualMember
      })

      if (!acc[groupName]) {
        acc[groupName] = {
          groupID,
          groupName,
          totalMembers: 0,
          members: [],
        }
      }

      acc[groupName].totalMembers += formattedMembers.length
      acc[groupName].members.push(...formattedMembers)

      return acc
    }, {})

    setAggregatedData(Object.values(aggregatedGroups))
  }, [applications, username])

  const viewMembersRenderer = (params) => {
    const { data } = params

    const groupMembers = data.members || []

    return (
      <Link
        to={{
          pathname: "/groupmembers",
          state: {
            groupID: data.groupID,
            groupName: data.groupName,
            groupMembers,
          },
        }}
      >
        View Members
      </Link>
    )
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Group Name",
        field: "groupName",
        sortable: true,
        filter: true,
      },
      { headerName: "Total Members", field: "totalMembers", sortable: true },
      {
        headerName: "View Members",
        field: "view",
        cellRenderer: viewMembersRenderer,
        pinned: "right",
        resizable: false,
        sortable: false,
        filter: false,
      },
    ],
    []
  )

  return (
    <Container fluid className="main-content-container px-4">
      <div
        className="ag-theme-alpine"
        style={{
          height: "600px",
          width: "100%",
          marginTop: "20px",
          marginLeft: "-25px",
          marginRight: "105px",
        }}
      >
        <AgGridReact
          rowData={aggregatedData}
          columnDefs={columnDefs}
          pagination={true}
          paginationAutoPageSize={true}
          defaultColDef={{ sortable: true, filter: true, resizable: true }}
        />
      </div>
    </Container>
  )
}

export default GroupsMembers
