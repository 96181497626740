import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { makeStyles } from "@material-ui/core/styles"
import { Tooltip } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import CssBaseline from "@material-ui/core/CssBaseline"
import Svg from "react-inlinesvg"
import { creditScoreActions } from "../_actions"
import { Loading } from "../../../components/exceptions"
import {
  applicationActions,
  ApplicationDetailsActions,
} from "../../applicationManager"
import { Comments } from "../../comments"
import "./CreditScoreCard.scss"
import formatNumber from "../../../utils/FormatNumber"
import formatNumericValue from "../../../utils/FormatNumericValue"
import { useTranslation } from "react-i18next"
const speedometerSVG = require("../../../assets/images/icons/speedometer.svg")
const armSVG = require("../../../assets/images/icons/arm.svg")

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "white",
    color: "#001642",
    fontFamily: "Rubik",
  },
  logList: {
    listStyle: "none",
    padding: 0,
    marginLeft: "20px",
    marginBottom: "20px",
    "& li": {
      display: "flex",
    },
  },
  scoreList: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    marginBottom: "20px",
    "& li": {
      display: "flex",
    },
  },
  scoreValue: {
    color: "var(--primary)",
    marginLeft: "auto",
  },
  card: {
    padding: "20px 40px",
    "&.main-scorecard ul": {
      paddingLeft: "20px",
    },
    "&.main-score": {
      marginTop: "20px",
      margin: "7px",
      padding: "20px 30px",
      backgroundColor: "#eef4f9",
      display: "flex",
      alignItems: "flex-start",
      borderRadius: "7px",
      border: "solid 1px #d8e8f4",
    },
  },
  scoreCol: {
    display: "flex",
    flexDirection: "column-reverse",
    textAlign: "center",
    "&.score-maximum": {
      maxWidth: "70px",
    },
  },
  scoreGrade: {
    fontSize: "12px",
    lineHeight: "1.5",
    letterSpacing: "-0.1px",
    color: "#8d9aa9",
  },
  scoreGradeValue: {
    fontSize: "18px",
    fontWeight: "600",
    letterSpacing: "0.6px",
    color: "var(--primary)",
    lineHeight: "1",
  },
  scoreGroup: {
    display: "flex",
    flexDirection: "column",
    "&.score-group": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -40%)",
    },
  },
  finalTitle: {
    fontSize: "20px",
    fontWeight: "600",
    flex: "0 0 100px",
    lineHeight: "1.32",
    marginLeft: "auto",
    fontFamily: "Rubik",
    color: "#002060",
  },
  cardTitle: {
    fontSize: "22px",
    paddingBottom: "4px",
    borderBottom: "4px solid #eef4f9",
    fontWeight: "600",
    color: "#002060",
    fontFamily: "Rubik",
  },
  categoryTitle: {
    fontSize: "17px",
    fontWeight: "600",
    margin: 0,
    color: "#002060",
    fontFamily: "Rubik",
    textTransform: "capitalize",
  },
}))

export const CreditScoreCard = ({ id, xformId, xformversion }) => {
  const [report, setReport] = useState()
  const score = useSelector((state) => {
    const {
      creditScore: { score: data },
    } = state
    return data
  })
  const reportData = useSelector((state) => {
    const {
      creditScore: { report: data },
    } = state
    return data
  })
  const changeLogs = useSelector((state) => {
    const {
      creditScore: { changeLogs: data },
    } = state
    return data
  })

  const applications = useSelector((state) => state.applications)
  const { items } = applications
  const { crb_value, crb_band, crb_probability, crb_reason } = items[0].json

  const getCrbInfo = () => {
    return `Positive Credit Score: ${crb_value}
    Grade: ${crb_band}
    Probability: ${crb_probability}%
    Reason: ${crb_reason}
    `
  }

  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(creditScoreActions.getScoreCard(id))
    dispatch(applicationActions.getApplicationbyID(id))
    dispatch(creditScoreActions.getJsonChanges(id))
    dispatch(creditScoreActions.getCreditScoreReport(id))
  }, [dispatch, id])

  useEffect(() => {
    if (reportData) {
      setReport(reportData)
    }
  }, [reportData])

  if (!score) {
    return <Loading />
  }

  const { scoreCard, finalScore } = score

  return (
    <>
      <div className={classes.container}>
        <CssBaseline />
        <div className="score-report-wrapper flex">
          <div className="w-50">
            <div className={`${classes.card} applicant-details`}>
              <h2 className={classes.cardTitle}>
                {t(
                  "module.creditScore.component.creditScoreCard.loanApplicantDetails"
                )}
              </h2>
              <ul className={classes.scoreList}>
                {report &&
                  report.map((item, idx) => {
                    const { label, value } = item

                    return (
                      <div key={idx}>
                        <ul className={classes.scoreList}>
                          <li>
                            <span className={classes.scoreTitle}>{label}</span>
                            <span className={classes.scoreValue}>
                              {formatNumericValue(value)}
                            </span>
                          </li>
                        </ul>
                      </div>
                    )
                  })}
                {report && report.length === 0 && "No details to show."}
              </ul>
            </div>
            <div className={`${classes.card} main-assumptions-score`}>
              <h2 className={classes.cardTitle}>
                {t("module.creditScore.component.creditScoreCard.changeLog")}
              </h2>
              <ul className={classes.scoreList}>
                {changeLogs &&
                  changeLogs.map((item, idx) => {
                    return (
                      <div key={idx}>
                        <h4 className={classes.categoryTitle}>
                          {item.user.toLowerCase()}{" "}
                          <span className="log-date">
                            on {item.date.slice(0, 19)}
                          </span>
                        </h4>
                        <div className="log-sub-title">
                          {t(
                            "module.creditScore.component.creditScoreCard.previous"
                          )}
                        </div>
                        <ul className={classes.logList}>
                          {item.previous &&
                            item.previous !== {} &&
                            Object.keys(item.previous).map((key, index) => (
                              <li key={index}>
                                <span className={classes.scoreTitle}>
                                  {key}
                                </span>
                                <span className={classes.scoreValue}>
                                  {(typeof item.previous[key] === "string" ||
                                    typeof item.previous[key] === "number") &&
                                    item.previous[key]}
                                </span>
                              </li>
                            ))}
                        </ul>
                        <div className="log-sub-title">
                          {t(
                            "module.creditScore.component.creditScoreCard.new"
                          )}
                        </div>
                        <ul className={classes.logList}>
                          {item.new &&
                            item.new !== {} &&
                            Object.keys(item.new).map((key, index) => (
                              <li key={index}>
                                <span className={classes.scoreTitle}>
                                  {key}
                                </span>
                                <span className={classes.scoreValue}>
                                  {(typeof item.new[key] === "string" ||
                                    typeof item.new[key] === "number") &&
                                    item.new[key]}
                                </span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )
                  })}
                {changeLogs && changeLogs.length === 0 && "No logs to show."}
              </ul>
            </div>
          </div>
          <div className="w-50">
            <div className={`${classes.card} main-score grade-container`}>
              <div className="grade-grids">
                <div className="grade-grids-final-title-grade">
                  <h2 className={classes.finalTitle}>
                    {t(
                      "module.creditScore.component.creditScoreCard.finalGrade"
                    )}
                  </h2>
                </div>
                <div className="grade-grids-final-score-grade">
                  <span className={`${classes.scoreGradeValue} score-value`}>
                    {finalScore.grade}
                  </span>
                </div>
                <div className="grade-grids-final-title-points">
                  <h2 className={classes.finalTitle}>
                    {t(
                      "module.creditScore.component.creditScoreCard.totalPoints"
                    )}
                  </h2>
                </div>
                <div className="grade-grids-final-score-points">
                  <span className={`${classes.scoreGradeValue} score-value`}>
                    {parseInt(finalScore.totalScore, 10)}
                  </span>
                  <span className={classes.scoreGrade}>
                    {" "}
                    {t(
                      "module.creditScore.component.creditScoreCard.pointsOutOf"
                    )}{" "}
                    {formatNumber(parseInt(finalScore.maximumPoint, 10))}
                  </span>
                </div>
              </div>
              <div className={`${classes.scoreCol} relative ring`}>
                <span className={`${classes.scoreGroup} score-group`}>
                  <div className={`rotate${score.finalScore.grade}`}>
                    <Svg className="arm" src={armSVG} />
                  </div>
                </span>
                <Svg className="progress-ring" src={speedometerSVG} />
              </div>
            </div>
            <div className={`${classes.card} main-scorecard pb-0`}>
              <h2 className={classes.cardTitle}>
                {t("module.creditScore.component.creditScoreCard.scoreCard")}
              </h2>
              {scoreCard.map((category, idx) => {
                const { title, entries } = category
                if (isEmpty(entries)) return null
                return (
                  <div key={idx}>
                    <h4 className={classes.categoryTitle}>
                      {title.toLowerCase()}
                    </h4>
                    <ul className={classes.scoreList}>
                      {Object.keys(entries).map((key, idx2) => (
                        <li key={idx2}>
                          <span className={classes.scoreTitle}>{key}</span>
                          {key === "CRB" && (
                            <Tooltip placement="right" title={getCrbInfo()}>
                              <InfoIcon color="secondary" fontSize="small" />
                            </Tooltip>
                          )}
                          <span className={classes.scoreValue}>
                            {entries[key].label}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              })}
              <h2 className={classes.cardTitle}>
                {t("module.creditScore.component.creditScoreCard.comments")}
              </h2>
              <Comments id={id} />
            </div>
          </div>
        </div>
      </div>
      <ApplicationDetailsActions id={id} saveDisabled={true} />
    </>
  )
}
