import { alertActions } from "../alert"
import { publisherConstants, publisherService } from "./index"

export const publisherActions = {
  syncApplications,
  syncIncompleteCropinApplications,
  syncCropinApplications,
  fetchCoffeePurchasesCropin,
  getPurchaseSyncLastModified,
  createGroupClientsMusoni,
}

/**
 * Get Trigger Publisher sync
 */
function syncApplications(surveyUid) {
  return (dispatch) => {
    dispatch(request())
    publisherService.syncApplications(surveyUid).then(
      (res) => {
        dispatch(success(res))
        dispatch(alertActions.success("Applications succesful synced"))
      },
      (errorMessage) => {
        dispatch(error(errorMessage))
        dispatch(alertActions.error("Error in syncing applications"))
      }
    )
  }

  function request(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_REQUEST, data }
  }
  function success(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_SUCCESS, data }
  }
  function error(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_ERROR, data }
  }
}

function syncIncompleteCropinApplications() {
  return (dispatch) => {
    dispatch(request())
    publisherService.syncIncompleteCropinApplications().then(
      (res) => {
        dispatch(success(res))
        dispatch(
          alertActions.success(
            "Request processing in the background. Check after a few minutes"
          )
        )
      },
      (errorMessage) => {
        dispatch(error(errorMessage))
        dispatch(alertActions.error("Fetch failed"))
      }
    )
  }

  function request(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_REQUEST, data }
  }
  function success(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_SUCCESS, data }
  }
  function error(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_FAILURE, data }
  }
}

function syncCropinApplications() {
  return (dispatch) => {
    dispatch(request())
    publisherService.syncCropinApplications().then(
      (res) => {
        dispatch(success(res))
        dispatch(
          alertActions.success(
            "Request processing in the background. Check after a few minutes"
          )
        )
      },
      (errorMessage) => {
        dispatch(error(errorMessage))
        dispatch(alertActions.error("Fetch failed"))
      }
    )
  }

  function request(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_REQUEST, data }
  }
  function success(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_SUCCESS, data }
  }
  function error(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_FAILURE, data }
  }
}

function fetchCoffeePurchasesCropin() {
  return (dispatch) => {
    dispatch(request())
    publisherService.fetchCoffeePurchasesCropin().then(
      (res) => {
        dispatch(success(res))
        dispatch(
          alertActions.success(
            "Request processing in the background. Check after a few minutes."
          )
        )
      },
      (errorMessage) => {
        dispatch(error(errorMessage))
        dispatch(alertActions.error("Fetch failed"))
      }
    )
  }

  function request(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_REQUEST, data }
  }
  function success(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_SUCCESS, data }
  }
  function error(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_FAILURE, data }
  }
}

function getPurchaseSyncLastModified() {
  return (dispatch) => {
    publisherService.getPurchaseSyncLastModified().then(
      (res) => {
        dispatch(success(res))
      },
      (errorMessage) => {}
    )
  }

  function success(data) {
    return { type: publisherConstants.PUBLISHER_LAST_SYNC, data }
  }
}

function createGroupClientsMusoni(payload) {
  return (dispatch) => {
    dispatch(request())
    publisherService.createGroupClientsMusoni(payload).then(
      (res) => {
        dispatch(success(res))
        dispatch(alertActions.success("Group created succesful synced"))
      },
      (err) => {
        dispatch(error(err))
        dispatch(alertActions.error(err.response.data.errors.join("; ")))
      }
    )
  }

  function request(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_REQUEST, data }
  }
  function success(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_SUCCESS, data }
  }
  function error(data) {
    return { type: publisherConstants.PUBLISHER_SYNC_FAILURE, data }
  }
}
