import { isEmpty } from "lodash"
import { reportsConstants, reportsService } from "."
import { userRoleConstants, hasAuthority } from "../../_helpers"

import { alertActions } from "../alert"

const fileSaver = require("file-saver")

/**
 * Export modules
 */
export const reportsActions = {
  filterReports,
  resetDateInputs,
  exportReports,
  exportApplicationsReports,
  resetHierarchyInputs,
  getHierarchy,
  repaymentReports,
  exportDefaultReports,
  exportRepaymentsReports,
  exportSummaryReports,
  getPaymentsSummaryReport,
  filterPaymentSummaryReport,
  filterGeneralPaymentSummaryReport,
  downloadMusoniReport,
  getReportQueue,
  fetchImpactReport,
}

/**
 * filter reports
 *
 * @param {*} surveyUuid string
 * @param {*} startDate string
 * @param {*} endDate string
 @param {*} mfiId string
 @param {*} hierarchyEntityId string
 */
function filterReports(payload) {
  return (dispatch) => {
    dispatch(request())
    if (
      (hasAuthority(userRoleConstants.FA_ANALYST) ||
        hasAuthority(userRoleConstants.FA_ADMIN)) &&
      !payload.mfiId
    ) {
      dispatch(alertActions.error("Please select an MFI first"))
    } else {
      reportsService.filterReports(payload).then(
        (data) => {
          dispatch(success(data))
          const dateData = {
            startDate: payload.startDate,
            endDate: payload.endDate,
          }
          dispatch(update(dateData))
          dispatch(updateHierarchy(payload.hierarchyEntityId))
          dispatch(updateMfiId(payload.mfiId))
          dispatch(updateSubmittedBy(payload.submittedBy))
        },
        (error) => {
          dispatch(failure(error))
          dispatch(alertActions.error(error.response.data.message))
        }
      )
    }
  }

  function request(data) {
    return { type: reportsConstants.REPORTS_FILTER_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.REPORTS_FILTER_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.REPORTS_FILTER_FAILURE, error }
  }

  function update(data) {
    return { type: reportsConstants.REPORTS_DATE_INPUTS, data }
  }
  function updateHierarchy(data) {
    return { type: reportsConstants.REPORTS_HIERARCHY_INPUTS, data }
  }
  function updateMfiId(data) {
    return { type: reportsConstants.REPORTS_MFI_ID, data }
  }
  function updateSubmittedBy(data) {
    return { type: reportsConstants.REPORTS_SUBMITTED_BY, data }
  }
}

/**
 * reset date inputs
 *
 */
function resetDateInputs() {
  return (dispatch) => {
    dispatch(
      update({
        startDate: null,
        endDate: null,
      })
    )
  }

  function update(data) {
    return { type: reportsConstants.REPORTS_DATE_INPUTS, data }
  }
}

/**
 * reset Hierarchy inputs
 *
 */
function resetHierarchyInputs() {
  return (dispatch) => {
    dispatch(update(null))
  }

  function update(data) {
    return { type: reportsConstants.REPORTS_HIERARCHY_INPUTS, data }
  }
}

/**
 * export reports
 *
 * @param {*} payload object
 */
function exportReports(payload) {
  return (dispatch, getState) => {
    dispatch(request())
    reportsService.exportReports(payload).then(
      (data) => {
        dispatch(success(data))
        if (!isEmpty(data)) {
          // eslint-disable-next-line no-case-declarations
          const date = new Date(Date.now())
          dispatch(alertActions.success("Downloading..."))
          // eslint-disable-next-line no-case-declarations
          const fileName = `report-${date.toLocaleString("en-GB", {
            timeZone: "UTC",
          })}.${payload.format}`
          fileSaver.saveAs(data.data, fileName)
        }
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Download failed"))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.REPORTS_EXPORT_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.REPORTS_EXPORT_SUCCESS, data }
  }
  // function update(data) { return { type: reportsConstants.SELECTED_REPAYMENT_REPORTS_DATE_INPUTS, data }; }
  function failure(error) {
    return { type: reportsConstants.REPORTS_EXPORT_FAILURE, error }
  }
}

/**
 * download reports
 *
 */
function downloadMusoniReport(username) {
  return (dispatch) => {
    dispatch(request())
    reportsService.downloadMusoniReport(username).then(
      (data) => {
        dispatch(success(data))
        if (!isEmpty(data)) {
          // eslint-disable-next-line no-case-declarations
          const date = new Date(Date.now())
          dispatch(alertActions.success("Success..."))
          // eslint-disable-next-line no-case-declarations
          const fileName = `field-collection-report-${date.toLocaleString(
            "en-GB",
            {
              timeZone: "UTC",
            }
          )}.pdf`
          fileSaver.saveAs(data.data, fileName)
        }
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Download failed"))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.REPORTS_DOWLOAD_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.REPORTS_DOWLOAD_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.REPORTS_DOWLOAD_FAILURE, error }
  }
}

/**
 * export applications reports
 *
 * @param {*} payload object
 */
function exportApplicationsReports(payload) {
  return (dispatch, getState) => {
    dispatch(request())
    reportsService.exportApplicationsReports(payload).then(
      (data) => {
        dispatch(
          alertActions.success(
            "Report downloading in the background. Check Report Queue for Progress."
          )
        )
      },
      (error) => {
        dispatch(alertActions.error(error))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.REPORTS_EXPORT_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.REPORTS_EXPORT_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.REPORTS_EXPORT_FAILURE, error }
  }
}

/**
 * export reports queue
 *
 */
function getReportQueue() {
  return (dispatch, getState) => {
    dispatch(request())
    reportsService.getReportQueue().then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.REPORTS_QUEUE_REQUEST }
  }
  function success(data) {
    return { type: reportsConstants.REPORTS_QUEUE_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.REPORTS_QUEUE_FAILURE, error }
  }
}

/**
 * export summary reports
 *
 * @param {*} payload object
 */
function exportSummaryReports(payload) {
  return (dispatch, getState) => {
    dispatch(request())
    reportsService.exportSummaryReports(payload).then(
      (data) => {
        dispatch(success(data))
        if (!isEmpty(data)) {
          // eslint-disable-next-line no-case-declarations
          const date = new Date(Date.now())
          dispatch(alertActions.success("Downloading..."))
          // eslint-disable-next-line no-case-declarations
          const fileName = `report-${date.toLocaleString("en-GB", {
            timeZone: "UTC",
          })}.${payload.format}`
          fileSaver.saveAs(data.data, fileName)
        }
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error("Download failed"))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.SUMMARY_REPORTS_EXPORT_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.SUMMARY_REPORTS_EXPORT_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.SUMMARY_REPORTS_EXPORT_FAILURE, error }
  }
}

/**
 * get hierarchy
 *
 * @param {*} surveyUuid
 */
function getHierarchy(mfiId) {
  return (dispatch) => {
    dispatch(request())
    reportsService.getHierarchy(mfiId).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.response.data.message))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.HIERARCHY_GET_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.HIERARCHY_GET_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.HIERARCHY_GET_FAILURE, error }
  }
}

/**
 * FETCH REPAYMENTS REPORTS
 *
 */
function repaymentReports(payload) {
  return (dispatch) => {
    dispatch(request())
    reportsService.repaymentReports(payload).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.response?.data?.errors[0]))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.DEFAULT_REPORTS_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.DEFAULT_REPORTS_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.DEFAULT_REPORTS_FAILURE, error }
  }
}

/**
 * EXPORT DEFAULT REPORTS
 *
 */
function exportDefaultReports(payload) {
  return (dispatch) => {
    dispatch(request())
    reportsService.exportDefaultReports(payload).then(
      (data) => {
        dispatch(success(data))
        if (!isEmpty(data)) {
          // eslint-disable-next-line no-case-declarations
          const date = new Date(Date.now())
          dispatch(alertActions.success("Downloading..."))
          // eslint-disable-next-line no-case-declarations
          const fileName = `report-${date.toLocaleString("en-GB", {
            timeZone: "UTC",
          })}.${payload.format}`
          fileSaver.saveAs(data.data, fileName)
        }
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.response.data.message))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.EXPORT_DEFAULT_REPORTS_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.EXPORT_DEFAULT_REPORTS_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.EXPORT_DEFAULT_REPORTS_FAILURE, error }
  }
}

/**
 * EXPORT REPAYMENTS REPORTS
 *
 */
function exportRepaymentsReports(payload) {
  return (dispatch) => {
    dispatch(request())
    reportsService.exportRepaymentsReports(payload).then(
      (data) => {
        dispatch(success(data))
        if (!isEmpty(data)) {
          // eslint-disable-next-line no-case-declarations
          const date = new Date(Date.now())
          dispatch(alertActions.success("Downloading..."))
          // eslint-disable-next-line no-case-declarations
          const fileName = `report-${date.toLocaleString("en-GB", {
            timeZone: "UTC",
          })}.${payload.format}`
          fileSaver.saveAs(data.data, fileName)
        }
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.response.data.message))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.EXPORT_REPAYMENTS_REPORTS_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.EXPORT_REPAYMENTS_REPORTS_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.EXPORT_REPAYMENTS_REPORTS_FAILURE, error }
  }
}

/**
 * GET PAMENTS SUMMARY REPORTS
 *
 */
function getPaymentsSummaryReport() {
  return (dispatch) => {
    dispatch(request())
    reportsService.getPaymentsSummaryReport().then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.response.data.message))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.PAYMENTS_REPORT_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.PAYMENTS_REPORT_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.PAYMENTS_REPORT_FAILURE, error }
  }
}

/**
 * FILTER PAYENTS SUMMARY REPORTS - BY DATE
 *
 */
function filterPaymentSummaryReport(startDate, endDate) {
  return (dispatch) => {
    dispatch(request())
    reportsService.filterPaymentSummaryReport(startDate, endDate).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.response.data.message))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.PAYMENTS_REPORT_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.PAYMENTS_REPORT_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.PAYMENTS_REPORT_FAILURE, error }
  }
}

/**
 * FILTER PAYENTS SUMMARY REPORTS - GENERAL
 *
 */
function filterGeneralPaymentSummaryReport(filters) {
  return (dispatch) => {
    dispatch(request())
    reportsService.filterGeneralPaymentSummaryReport(filters).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
        dispatch(alertActions.error(error.response.data.message))
      }
    )
  }

  function request(data) {
    return { type: reportsConstants.PAYMENTS_REPORT_REQUEST, data }
  }
  function success(data) {
    return { type: reportsConstants.PAYMENTS_REPORT_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.PAYMENTS_REPORT_FAILURE, error }
  }
}
function fetchImpactReport(payload) {
  return (dispatch) => {
    dispatch(request())
    reportsService.fetchImpactReportService(payload).then(
      (data) => {
        dispatch(success(data))
      },
      (error) => {
        dispatch(failure(error))
      }
    )
  }

  function request() {
    return { type: reportsConstants.IMPACT_REPORTS_REQUEST }
  }
  function success(data) {
    return { type: reportsConstants.IMPACT_REPORTS_SUCCESS, data }
  }
  function failure(error) {
    return { type: reportsConstants.IMPACT_REPORTS_FAILURE, error }
  }
}
