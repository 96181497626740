import React, { useState } from "react"
import { Card, CardMedia } from "@material-ui/core"

export const ImageCellRenderer = (props) => {
  const [isHovered, setIsHovered] = useState(false)
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 })

  const handleMouseEnter = (event) => {
    setIsHovered(true)
    updateHoverPosition(event)
  }

  const handleMouseMove = (event) => {
    updateHoverPosition(event)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const updateHoverPosition = (event) => {
    setHoverPosition({
      x: event.clientX,
      y: event.clientY - 220,
    })
  }

  return (
    <>
      <Card
        style={{
          maxWidth: 100,
          maxHeight: 100,
          overflow: "hidden",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <CardMedia
          component="img"
          image={props.value}
          alt="Image"
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      </Card>

      {isHovered && (
        <div
          style={{
            position: "fixed",
            top: hoverPosition.y,
            left: hoverPosition.x,
            zIndex: 9999,
            pointerEvents: "none",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            border: "1px solid #ccc",
            backgroundColor: "#fff",
            padding: 5,
          }}
        >
          <img
            src={props.value}
            alt="Enlarged preview"
            style={{ width: 200, height: 200, objectFit: "cover" }}
          />
        </div>
      )}
    </>
  )
}
