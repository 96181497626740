/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useEffect, useRef } from "react"
import PersonOutlineOutlinedIcon from "@material-ui/icons/LockOutlined"
import { useSelector, useDispatch } from "react-redux"
import currency from "currency.js"
import PropTypes from "prop-types"
import { FormGroup, Button } from "shards-react"

import {
  FormWithConstraints,
  Input,
  FieldFeedbacks,
  FieldFeedback,
} from "react-form-with-constraints-bootstrap4"

import { isEmpty, first, filter } from "lodash"
import { useTranslation } from "react-i18next"
import Header from "../../../components/widget/widgetTitle"
import { modalActions } from "../../modals"
import { applicationActions } from ".."

import "./RecalculateScore.scss"

export function RecalculateScore({ modalID, data }) {
  const { t } = useTranslation()
  const form = useRef(null)
  const dispatch = useDispatch()
  const state = useSelector((store) => store)
  const { id } = data
  const { applications, authentication } = state
  const {
    profile: {
      mfi: { currency: settingsCurrency },
    },
  } = authentication
  const { applicationMetadata } = applications
  const [gracePeriod, setGracePeriod] = useState(0)

  let loanItem = {}
  let loanTenor = {}
  let loanApplicant = {}
  let applicantLoan = 0
  let applicantTenor = 0
  let applicantName = ""

  if (!isEmpty(applicationMetadata)) {
    loanItem = first(
      applicationMetadata.filter(
        (item) =>
          item.type === "LOAN_AMOUNT" &&
          Number(item.applicationId) === Number(id)
      )
    )
    loanTenor = first(
      applicationMetadata.filter(
        (item) =>
          item.type === "LOAN_TENOR" &&
          Number(item.applicationId) === Number(id)
      )
    )
    loanTenor = loanTenor ? loanTenor : {}
    loanApplicant = first(
      applicationMetadata.filter(
        (item) =>
          item.type === "APPLICANT_NAME" &&
          Number(item.applicationId) === Number(id)
      )
    )

    applicantName = loanApplicant && loanApplicant.value
    applicantLoan = loanItem && loanItem.value
    applicantTenor = loanTenor && loanTenor.value
  }

  useEffect(() => {
    const applicationItem = first(
      applications.items?.filter((item) => Number(item.id) === Number(id))
    )
    const applicantGracePeriod =
      applicationItem.json["Grace Period"] !== undefined
        ? applicationItem.json["Grace Period"]
        : 0
    setGracePeriod(applicantGracePeriod)
  }, [applications])

  const { questionKey: amountQuestionKey } = loanItem
  const { questionKey: amountQuestionTenor } = loanTenor

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [defaults, setDefaults] = useState({
    newLoanAmount: applicantLoan,
    newLoanTenor: applicantTenor,
  })
  const [inputs, setInputs] = useState({
    newLoanAmount: applicantLoan,
    newLoanTenor: applicantTenor,
    newGracePeriod: gracePeriod,
  })

  useEffect(() => {
    setInputs((prevState) => ({
      ...prevState,
      newGracePeriod: gracePeriod,
    }))
  }, [gracePeriod])

  const [fullSubmission, setFullSubmission] = useState({})

  useEffect(() => {
    const { items } = applications

    const foundApplication = filter(items, { id })
    const [application] = foundApplication

    foundApplication && setFullSubmission(application)
  }, [id, applications])

  useEffect(() => {
    if (isEmpty(fullSubmission)) {
      dispatch(applicationActions.getApplicationbyID(id))
    }
  }, [dispatch, id, fullSubmission])

  useEffect(() => {
    function shouldEnableSubmitButton() {
      return inputs.newLoanTenor && inputs.newLoanAmount
    }
    setSubmitButtonDisabled(!shouldEnableSubmitButton())
  }, [inputs])

  useEffect(() => {
    function shouldEnableSubmitButton() {
      return inputs.newLoanTenor && inputs.newLoanAmount
    }
    setDefaults({ newLoanAmount: applicantLoan, newLoanTenor: applicantTenor })
    setSubmitButtonDisabled(!shouldEnableSubmitButton())
  }, [applicantLoan, applicantTenor])

  useEffect(() => {
    setInputs({ newLoanAmount: applicantLoan, newLoanTenor: applicantTenor })
  }, [applicantLoan, applicantTenor])

  const handleChange = ({ target, currentTarget }) => {
    const { name, value } = target
    setInputs((prevState) => ({ ...prevState, [name]: value }))
    form.current.validateFields(currentTarget)
  }

  const save = async () => {
    await form.current.validateForm()

    if (isEmpty(inputs)) {
      return false
    }

    // SAVE AND CALCULATE NEW SCORE
    const data = {
      applicationId: id,
      approvalLoanAmount: Number(inputs.newLoanAmount),
      approvalLoanAmountQuestionKey: amountQuestionKey,
      approvalLoanTenor: parseInt(inputs.newLoanTenor),
      approvalLoanTenorQuestionKey: amountQuestionTenor,
      gracePeriod: parseInt(inputs.newGracePeriod),
      gracePeriodQuestionKey: "loan/loan_grace",
    }

    dispatch(applicationActions.editApplicationLoanDetails(data))
    closeModal()

    return false
  }

  const closeModal = () => {
    dispatch(modalActions.hideModal(modalID))
  }

  return (
    <div className="site-modal scoring-variables__add-variable">
      <Header
        title={"Recalculate Credit Score"}
        icon={<PersonOutlineOutlinedIcon />}
        close={true}
        closeHandler={closeModal}
      />
      <div className="p-10">
        <h2 className="pb-0 form-group-section">
          {t("module.applicationManager.modal.recalculateScore.details")}
        </h2>
        <div>
          <span className="font-bold">
            {t(
              "module.applicationManager.modal.recalculateScore.applicantName"
            )}
          </span>{" "}
          <span>{applicantName}</span>
        </div>
        <div>
          <span className="font-bold">
            {t(
              "module.applicationManager.modal.recalculateScore.currentAmount"
            )}
          </span>{" "}
          <span>
            {`${settingsCurrency} ${currency(applicantLoan).format()}`}
          </span>
        </div>
        <div className="mb-3">
          <span className="font-bold">
            {t("module.applicationManager.modal.recalculateScore.currentTenor")}
          </span>{" "}
          <span>
            {applicantTenor}{" "}
            {t("module.applicationManager.modal.recalculateScore.months")}
          </span>
        </div>

        <h4
          className="pb-1 mb-0 border-0 form-group-section text-base text-lg"
          style={{ fontSize: "20px" }}
        >
          {t("module.applicationManager.modal.recalculateScore.adjusted")}
        </h4>
        <FormWithConstraints ref={form} noValidate className="shadow-none">
          <FormGroup>
            <label htmlFor="newLoanAmount">
              {t("module.applicationManager.modal.recalculateScore.newAmount")}
            </label>
            <Input
              className="form-control mb-2"
              name="newLoanAmount"
              defaultValue={defaults.newLoanAmount || ""}
              value={inputs.newLoanAmount}
              onChange={handleChange}
              type="number"
              required
            />
            <FieldFeedbacks for="newLoanAmount">
              <FieldFeedback when="*" />
            </FieldFeedbacks>
          </FormGroup>

          <FormGroup>
            <label htmlFor="newLoanTenor">
              {t("module.applicationManager.modal.recalculateScore.newTenor")}
            </label>
            <Input
              className="form-control mb-2"
              name="newLoanTenor"
              defaultValue={defaults.newLoanTenor || ""}
              value={inputs.newLoanTenor}
              onChange={handleChange}
              type="number"
              required
            />
            <FieldFeedbacks for="newLoanTenor">
              <FieldFeedback when="*" />
            </FieldFeedbacks>
            <label htmlFor="newGracePeriod">
              {t(
                "module.applicationManager.modal.recalculateScore.newGracePeriod"
              )}
            </label>
            <Input
              className="form-control mb-2"
              name="newGracePeriod"
              value={inputs.newGracePeriod}
              onChange={handleChange}
              type="number"
              required
            />
            <FieldFeedbacks for="newGracePeriod">
              <FieldFeedback when="*" />
            </FieldFeedbacks>
          </FormGroup>
          <div className="btn-group-actions site-modal__actions flex">
            <Button
              theme="primary"
              disabled={submitButtonDisabled}
              className={`ml-auto ${
                submitButtonDisabled && `btn-recalculate`
              } `}
              onClick={() => save()}
            >
              {t(
                "module.applicationManager.modal.recalculateScore.recalculate"
              )}
            </Button>
            <Button
              theme="secondary"
              className="ml-2"
              onClick={() => {
                closeModal()
              }}
            >
              {t("module.applicationManager.modal.recalculateScore.cancel")}
            </Button>
          </div>
        </FormWithConstraints>
      </div>
    </div>
  )
}

RecalculateScore.propTypes = {
  modalID: PropTypes.string,
  data: PropTypes.object,
}
