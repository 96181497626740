/* eslint-disable no-unused-expressions */
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container,
  FormGroup,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core"
import { FormSelect } from "shards-react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Svg from "react-inlinesvg"
import Header from "../../../components/widget/widgetTitle"
import { authenticationActions } from "../_actions"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

const hidePasswordSVG = require("../../../assets/images/icons/hide-password.svg")
const showPasswordSVG = require("../../../assets/images/icons/show-password.svg")
const userHeaderSVG = require("../../../assets/images/icons/user-login-icon.svg")

const REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])[a-zA-Z\d\W]{12,}$/g

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 46px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "400px",
    padding: 0,
    backgroundColor: theme.palette.common.white,
    borderRadius: "4px",
    boxShadow: "0 30px 50px 0 rgba(119, 151, 178, 0.2)",
    border: "solid 1px #cee0e9",
    color: "#002060",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: "35px",
    paddingTop: "40px",
  },
  submit: {
    backgroundColor: "var(--primary)",
    margin: theme.spacing(3, 0, 2),
  },
  metaLinks: {
    "& a": {
      display: "block",
    },
  },
}))

export const ResetPassword = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const resetSmsSuccess = useSelector(
    (state) => state.authentication.resetSmsSuccess
  )

  const { resetPasswordToken } = useParams()

  const [inputs, setInputs] = useState({
    newPassword: { password: "", error: false, msg: "" },
    confirmPassword: { password: "", error: false, msg: "" },
  })
  const [passwordHidden, SetPasswordHidden] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  function handlePasswordChange(e) {
    const { password: newPassword } = inputs.newPassword
    const { name, value } = e.target
    const passwordMatch = newPassword === value
    let requiredPW = value.match(REGEX)
    if (name === "confirmPassword") {
      requiredPW = passwordMatch
    }
    setInputs((prevState) => ({
      ...prevState,
      [name]: { password: value, error: !requiredPW },
    }))
  }

  const [otp, setOtp] = useState("")
  const [error, setError] = useState(false)

  function handleSubmit(e) {
    e.preventDefault()
    setSubmitting(true)

    if (inputs.confirmPassword.password !== inputs.newPassword.password) {
      setInputs((prevState) => ({
        ...prevState,
        confirmPassword: { ...prevState.confirmPassword, error: true },
      }))
      setSubmitting(false)
      return
    }

    if (resetSmsSuccess) {
      if (!otp) {
        setSubmitting(false)
        setError(true)
        return
      }
      dispatch(
        authenticationActions.resetPasswordSms({
          newPassword: inputs.newPassword.password,
          confirmPassword: inputs.confirmPassword.password,
          resetPasswordOtp: otp,
        })
      )
    } else {
      dispatch(
        authenticationActions.resetPassword({
          newPassword: inputs.newPassword.password,
          confirmPassword: inputs.confirmPassword.password,
          resetPasswordToken,
        })
      )
    }

    setSubmitted(true)
    setSubmitting(false)
  }

  function togglePasswordShow() {
    SetPasswordHidden(!passwordHidden)
  }
  const { t } = useTranslation()

  function handleClickLocale(lang) {
    i18next.changeLanguage(lang)
  }

  function handleLocaleChange(e) {
    const {
      target: { value },
    } = e
    handleClickLocale(value)
  }
  const handleOtpChange = (event) => {
    const newValue = event.target.value
    if (/^[0-9]{6}$/.test(newValue)) {
      setOtp(newValue)
      setError(false)
    } else {
      setOtp(newValue)
      setError(true)
    }
  }

  return (
    <Container className={classes.container} component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <Header
          title={t(
            "module.authentication.component.forgotPassword.headerTitle"
          )}
          icon={
            <Svg className="text-center d-inline-block" src={userHeaderSVG} />
          }
        />
        <form
          noValidate
          name="form"
          className={classes.form}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <p>
            {t("module.authentication.component.forgotPassword.instructions")}
          </p>
          <>
            {resetSmsSuccess && (
              <FormGroup className="relative mb-1">
                <label htmlFor="otpcode">
                  {t("module.authentication.component.forgotPassword.otpcode")}
                </label>
                <TextField
                  variant="outlined"
                  type="text"
                  value={otp}
                  onChange={handleOtpChange}
                  error={error}
                  helperText={error && "Please enter only numbers of 6 digits"}
                  inputProps={{
                    maxLength: 6,
                    autoComplete: "off",
                  }}
                />
              </FormGroup>
            )}
          </>

          <FormGroup className="relative mb-1">
            <label htmlFor="newPassword">
              {t("module.authentication.component.forgotPassword.newPassword")}
            </label>
            <TextField
              error={inputs.newPassword.error}
              type={passwordHidden ? "password" : "text"}
              name="newPassword"
              value={inputs.newPassword.password}
              onChange={handlePasswordChange}
              variant="outlined"
              helperText={
                inputs.newPassword.error
                  ? "Password must be at least 12 characters long at least 1 uppercase letter, 1 lowercase letter, 1 special character (!@#$%^&*()-_=+) and 1 number"
                  : ""
              }
              inputProps={{
                autoComplete: "new-password",
              }}
            />

            <div className="toggle-password" onClick={togglePasswordShow}>
              {passwordHidden && (
                <Svg
                  className="text-center d-inline-block"
                  src={hidePasswordSVG}
                />
              )}
              {!passwordHidden && (
                <Svg
                  className="text-center d-inline-block"
                  src={showPasswordSVG}
                />
              )}
            </div>
          </FormGroup>
          <FormGroup className="relative mb-3">
            <label htmlFor="confirmPassword">
              {t(
                "module.authentication.component.forgotPassword.confirmPassword"
              )}
            </label>
            <TextField
              error={inputs.confirmPassword.error}
              type={passwordHidden ? "password" : "text"}
              name="confirmPassword"
              value={inputs.confirmPassword.password}
              onChange={handlePasswordChange}
              variant="outlined"
              helperText={
                inputs.confirmPassword.error
                  ? "Password don't match"
                  : inputs.confirmPassword.msg
              }
            />

            <div className="toggle-password" onClick={togglePasswordShow}>
              {passwordHidden && (
                <Svg
                  className="text-center d-inline-block"
                  src={hidePasswordSVG}
                />
              )}
              {!passwordHidden && (
                <Svg
                  className="text-center d-inline-block"
                  src={showPasswordSVG}
                />
              )}
            </div>
            <div className="language-selector__dropdown">
              <label htmlFor="language">
                {t("module.authentication.component.login.language")}
              </label>
              <FormSelect onChange={(e) => handleLocaleChange(e)}>
                <option value="" disabled selected hidden>
                  {(() => {
                    switch (localStorage.getItem("i18nextLng")) {
                      case "en":
                        return "English"
                      case "fr":
                        return "French"
                      case "my":
                        console
                        return "ဗမာ"
                      default:
                        return "Change Language"
                    }
                  })()}
                </option>
                <option value="en">English</option>
                <option value="fr">French</option>
                <option value="my">ဗမာ</option>
              </FormSelect>
            </div>
          </FormGroup>

          <FormGroup className="flex justify-between">
            <Button
              color="primary"
              variant="contained"
              className="d-table ml-auto btn"
              type="submit"
              disabled={
                inputs.newPassword.error ||
                inputs.confirmPassword.error ||
                !inputs.newPassword.password ||
                !inputs.confirmPassword.password ||
                submitting
              }
            >
              {t("module.authentication.component.forgotPassword.submit")}
              {submitting && <CircularProgress size={15} />}
            </Button>
          </FormGroup>
        </form>
      </div>
    </Container>
  )
}
