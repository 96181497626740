import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty, replace } from "lodash"
import BasicTable from "../../../components/baseTable/BasicTable"
import { LoadingView } from "../../../views"
import { alertActions } from "../../alert"
import { modalActions } from "../../modals"
import { mfiManagerActions } from "../../mfiManager"
import tableSort from "./tableSort"
import generatePayload from "./generatePayload"
import { Tabs, Tab, TabList, TabPanel } from "react-tabs"

import "./ApplicationTable.scss"
import { useTranslation } from "react-i18next"
const sortIcon = require("../../../assets/images/icons/sort-icon.png")
const sortIconDesc = require("../../../assets/images/icons/sort-icon-desc.png")
const sortIconAsc = require("../../../assets/images/icons/sort-icon-asc.png")
const filterIcon = require("../../../assets/images/icons/filter-icon.png")

export function ApplicationTable({
  data,
  dateColumnFilters: dateColumnFiltersData,
  hasWorkflow,
  applicationColumns,
  sortStatus,
  group,
}) {
  const {
    profile: {
      mfi: { id: mfiId },
      username,
    },
  } = useSelector((state) => state.authentication)

  const dispatch = useDispatch()
  const [applicationsDataDateSorted, setApplicationsDataDateSorted] = useState(
    {}
  )
  const [applicationsData, setApplicationsData] = useState(data)
  const [columnSortData, setColumnSortData] = useState({})
  const [prev, setPrev] = useState(null)
  const { t } = useTranslation()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  let defaultColumns2 = [
    { key: "id", name: t("component.application.defaultColumns.id") },
    { key: "_stage", name: t("component.application.defaultColumns._stage") },
    {
      key: "_submissionDate",
      name: t("component.application.defaultColumns._submissionDate"),
    },
    {
      key: "_submittedBy",
      name: t("component.application.defaultColumns._submittedBy"),
    },
    {
      key: "currentUser",
      name: t("component.application.defaultColumns.currentUser"),
    },
    {
      key: "_workflowStatus",
      name: t("component.application.defaultColumns._workflowStatus"),
    },
    { key: "_surveyStatus", name: "surveyStatus" },
    {
      key: "approvalAmount",
      name: t("component.application.defaultColumns.approvalAmount"),
    },
    /* { key: 'approvalLoanTenor', name: t('component.application.defaultColumns.approvalLoanTenor')} */
  ]
  let sortableColumns = [
    { columnKey: "id", columnTitle: "id", dataType: "NUMBER" },
    { columnKey: "_stage", columnTitle: "_stage", dataType: "TEXT" },
    {
      columnKey: "_submissionDate",
      columnTitle: "_submissionDate",
      dataType: "TEXT",
    },
    {
      columnKey: "_submittedBy",
      columnTitle: "_submittedBy",
      dataType: "TEXT",
    },
    { columnKey: "currentUser", columnTitle: "currentUser", dataType: "TEXT" },
    {
      columnKey: "_workflowStatus",
      columnTitle: "_workflowStatus",
      dataType: "TEXT",
    },
    {
      columnKey: "_surveyStatus",
      columnTitle: "_surveyStatus",
      dataType: "TEXT",
    },
    {
      columnKey: "approvalAmount",
      columnTitle: "approvalAmount",
      dataType: "NUMBER",
    },
    /* {columnKey: 'approvalLoanTenor', columnTitle: 'approvalLoanTenor', dataType: 'NUMBER'} */
  ]
  const columns = []
  const actions = []
  let tableHeader = []
  let tableHeaderRaw = []

  // get custom menus to conditionaly show the view profile action
  useEffect(() => {
    dispatch(mfiManagerActions.getCustomMenus(mfiId))
  }, [dispatch, mfiId])

  // update data every time data is filtered by date
  useEffect(() => {
    applicationsDataDateSorted &&
      setApplicationsData(applicationsDataDateSorted)
  }, [applicationsDataDateSorted])

  useEffect(() => {
    const mutableData = data
    let newDataColumns = []
    if (!dateColumnFiltersData["key"]) {
      const sortedDataObj = JSON.parse(
        localStorage.getItem("staticApplicationsData")
      )
      Object.keys(sortedDataObj).map((item, index) =>
        newDataColumns.push(sortedDataObj[item])
      )
    } else {
      newDataColumns = mutableData.filter((item) => {
        const columnKey = dateColumnFiltersData.key
        if (item[columnKey]) {
          const rowDate = new Date(item[columnKey]).toJSON().slice(0, 10)
          const fromDate = new Date(dateColumnFiltersData.from)
            .toJSON()
            .slice(0, 10)
          const to = new Date(dateColumnFiltersData.to).toJSON().slice(0, 10)
          return rowDate >= fromDate && to >= rowDate
        } else {
          return null
        }
      })
      scrollBack(dateColumnFiltersData.key)
    }
    setApplicationsDataDateSorted(newDataColumns)
  }, [dateColumnFiltersData, data])

  const alertNoApplication = t("alertActions.applications.noApplication")
  const alertFilterReset = t("alertActions.applications.filterReset")

  useEffect(() => {
    const mutableData = data
    let newDataColumns = []
    newDataColumns =
      sortStatus &&
      sortStatus !== "reset" &&
      mutableData.filter((item) => {
        return item["_workflowStatus"].toLowerCase() === sortStatus
      })
    if ((sortStatus && newDataColumns.length === 0) || sortStatus === "reset") {
      sortStatus !== "reset" &&
        dispatch(alertActions.error(`${alertNoApplication}: ${sortStatus}`))
      sortStatus === "reset" && dispatch(alertActions.success(alertFilterReset))
      newDataColumns = []
      const staticDataObj = JSON.parse(
        localStorage.getItem("staticApplicationsData")
      )
      Object.keys(staticDataObj).map((item, index) =>
        newDataColumns.push(staticDataObj[item])
      )
    }
    setApplicationsDataDateSorted(newDataColumns)
  }, [sortStatus, data, dispatch, alertNoApplication, alertFilterReset])

  const sortNumberText = useCallback(() => {
    // ASC 0-9, A-Z DESC 9-0, Z-A
    const key = columnSortData.key
    const prevKeyDirection = columnSortData.prev
    const dataType = columnSortData.dataType
    let dir = "ASC"
    prevKeyDirection &&
      prevKeyDirection.key === key &&
      (dir = prevKeyDirection.direction === "ASC" ? "DESC" : null)
    let sortedData
    if (!dir) {
      sortedData = []
      const sortedDataObj = JSON.parse(
        localStorage.getItem("staticApplicationsData")
      )
      Object.keys(sortedDataObj).map((item, index) =>
        sortedData.push(sortedDataObj[item])
      )
      setPrev({})
    } else {
      sortedData = tableSort(dataType, data, key, dir)
      if (!sortedData) {
        dispatch(alertActions.error("Can't sort empty column"))
        sortedData = []
        const sortedDataObj = JSON.parse(
          localStorage.getItem("staticApplicationsData")
        )
        Object.keys(sortedDataObj).map((item, index) =>
          sortedData.push(sortedDataObj[item])
        )
        setPrev({})
      } else {
        setPrev({ key: key, direction: dir })
      }
    }
    setApplicationsData(sortedData)
    scrollBack(key)
  }, [columnSortData, data, dispatch])

  const scrollBack = (key) => {
    // This is a hack to scroll user back to column they are sorting
    window.setTimeout(() => {
      const elementsHeader = document.getElementsByClassName(
        "BaseTable__header-cell"
      )
      const elementsRow = document.getElementsByClassName("BaseTable__row-cell")
      const contentContainer = document.getElementsByClassName("sidebar-open")

      let idx
      Array.from(elementsHeader).forEach(
        (item, i) => item.dataset.key === key && (idx = i)
      )
      idx && elementsHeader[idx + 1].scrollIntoView()
      idx && elementsRow[idx + 1].scrollIntoView()
      contentContainer[0].scrollIntoView()
      return
    }, 5)
  }

  useEffect(() => {
    sortNumberText()
  }, [columnSortData, sortNumberText])

  /*add select column if filtered by group and subRoleStageFinal
    this section adds the functionality to select all application and disburse them as a group
  */
  const [selectedRows, setSelectedRows] = useState([])
  const [isAllSelected, setIsAllSelected] = useState(false)

  useEffect(() => {
    // Update isAllSelected state based on selectedRows
    const allSelected = applicationsData.length === selectedRows.length
    setIsAllSelected(allSelected)
  }, [selectedRows, applicationsData.length])

  const handleSelectChange = (rowKey) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = [...prevSelectedRows]
      const rowKeyIndex = newSelectedRows.indexOf(rowKey)
      if (rowKeyIndex > -1) {
        newSelectedRows.splice(rowKeyIndex, 1)
      } else {
        newSelectedRows.push(rowKey)
      }
      return newSelectedRows
    })
  }

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([])
    } else {
      const allSelectedRows = applicationsData.map((row) => row.id)
      setSelectedRows(allSelectedRows)
    }
    setIsAllSelected(!isAllSelected)
  }

  const checkboxColumn = {
    key: "select",
    title: (
      <input
        type="checkbox"
        checked={isAllSelected}
        onChange={handleSelectAllChange}
      />
    ),
    width: 50,
    cellRenderer: ({ rowData }) => {
      return (
        <input
          type="checkbox"
          checked={selectedRows.includes(rowData.id)}
          onChange={() => handleSelectChange(rowData.id)}
        />
      )
    },
  }
  /*
    end the select box section
  */

  if (isEmpty(applicationsData[0])) {
    return <LoadingView />
  }

  const columnSortFn = (key, dataType, prev) => {
    let direction = "ASC"
    const newColumnSortData = { key, direction, dataType, prev }
    setColumnSortData((prevState) => ({ ...prevState, ...newColumnSortData }))
    return
  }

  const dateColumnFilter = (key) => {
    // open modal for now to perform filter
    dispatch(modalActions.dateColumnFilter({ key, applicationsData }))
    return
  }

  const sortButton = (sortable, title, col, dataType) => {
    if (!sortable) {
      return title
    }
    let dir
    let icon
    prev && prev.key === col && (dir = prev.direction)
    if (dir === "ASC") {
      icon = sortIconAsc
    }
    if (dir === "DESC") {
      icon = sortIconDesc
    }
    if (!dir) {
      icon = sortIcon
    }

    return (
      <button
        onClick={(e) => {
          columnSortFn(col, dataType, prev)
          setApplicationsData("")
        }}
      >
        {title}
        <img src={icon} className="header-icon" alt={col} />
      </button>
    )
  }

  const filterDateButton = (key) => {
    return (
      <button onClick={() => dateColumnFilter(key)}>
        {replace(replace(key, "_", " "), "/", " ")}
        <img src={filterIcon} className="header-icon" alt={key} />
      </button>
    )
  }

  // check if survey has setup application columns
  if (applicationColumns.length > 0) {
    applicationColumns.forEach((applicationColumn) => {
      defaultColumns2.push({
        key: applicationColumn.columnTitle,
        name: applicationColumn.columnTitle,
      })
      sortableColumns.push({
        columnTitle: applicationColumn.columnTitle,
        dataType: applicationColumn.dataType,
        columnKey: applicationColumn.columnTitle,
      })
    })
  } else {
    Object.keys(applicationsData[0]).map((item, index) =>
      defaultColumns2.push({ key: item, name: item })
    )
  }
  // add empty column to ensure last column is not hidden under actions buttons
  defaultColumns2.push({ key: "", name: "" })
  // add action column last
  defaultColumns2.push({ key: "action", name: "action" })

  // remove duplicate columns
  const uniqueDefaultColumns = defaultColumns2.filter(
    (value, index, self) => index === self.findIndex((t) => t.key === value.key)
  )
  uniqueDefaultColumns.forEach((col, index) => {
    const result = generatePayload(
      col,
      sortableColumns,
      tableHeader,
      tableHeaderRaw,
      hasWorkflow,
      actions,
      sortButton,
      filterDateButton
    )
    columns.push(result.payload)
  })

  // sort and arrange data to match headers on xlsx download file
  let row = []
  applicationsData.forEach((item) => {
    let arr = []
    tableHeaderRaw.forEach((head) => {
      if (item[head.text] === 0) {
        arr.push({ text: 0 })
      } else if (!item[head.text]) {
        arr.push({ text: "" })
      } else {
        arr.push({ text: item[head.text] })
      }
    })
    row.push(arr)
  })

  const tableData = [
    {
      sheetName: "Sheet1",
      data: [tableHeader, ...row],
    },
  ]

  // store tableData in the local storage
  localStorage.setItem("tableData", JSON.stringify({ ...tableData }))

  const showMultipleDisburseActions =
    group.length > 0 &&
    selectedTabIndex === 1 &&
    applicationsData.filter((app) => app._workflowStatus === "Approved")
      .length > 0 &&
    applicationsData.every(
      (app) => app.currentUser === username && app.subRoleStageFinal
    )

  const showTenorAdjustActions =
    group.length > 0 &&
    selectedTabIndex === 0 &&
    applicationsData.filter((app) => app._workflowStatus === "Review").length >
      0 &&
    applicationsData.every(
      (app) => app.currentUser === username && !app.subRoleStageFinal
    )

  const tableColumns =
    showMultipleDisburseActions || showTenorAdjustActions
      ? [checkboxColumn, ...columns]
      : columns

  // Define filteredData before tabData
  const filteredData = {
    review: applicationsData.filter((app) => app._workflowStatus === "Review"),
    approved: applicationsData.filter(
      (app) => app._workflowStatus === "Approved"
    ),
    declined: applicationsData.filter(
      (app) => app._workflowStatus === "Declined"
    ),
    disbursed: applicationsData.filter(
      (app) => app._workflowStatus === "Disbursed"
    ),
    refused: applicationsData.filter(
      (app) => app._workflowStatus === "Refused"
    ),
  }
  // Use filteredData in tabData
  const tabData = [
    { title: "Review", data: filteredData.review },
    { title: "Approved", data: filteredData.approved },
    { title: "Declined", data: filteredData.declined },
    { title: "Disbursed", data: filteredData.disbursed },
    { title: "Refused", data: filteredData.refused },
  ]

  return (
    <Tabs
      selectedIndex={selectedTabIndex}
      onSelect={(index) => setSelectedTabIndex(index)}
    >
      <TabList>
        {tabData.map((tab, index) => (
          <Tab key={index}>{tab.title}</Tab>
        ))}
      </TabList>
      {tabData.map((tab, index) => (
        <TabPanel key={index}>
          <BasicTable
            fixed
            columns={tableColumns}
            data={tab.data}
            group={group}
            selectedRows={selectedRows}
            showMultipleDisburseActions={showMultipleDisburseActions}
            showTenorAdjustActions={showTenorAdjustActions}
          />
        </TabPanel>
      ))}
    </Tabs>
  )
}
