import React from "react"
import FinancialCharts from "./impactcharts" // Import your chart components
import "./Dashboard.scss" // Custom CSS for styling

const Dashboard = ({
  workflowStatusData,
  loanValuePerStaffData,
  loanValuesPerBranchData,
  loansPerBranchData,
  loansPerStaffData,
  currencyData,
}) => {
  return (
    <div className="dashboard-container">
      <div className="charts-flex">
        {/* Loans per Staff Chart */}
        <div className="chart-item">
          <h4>Loans per Staff</h4>
          {FinancialCharts.renderLoansPerStaffChart(loansPerStaffData)}
        </div>

        {/* Loan Value per Staff Chart */}
        <div className="chart-item">
          <h4>Loan Value per Staff ({currencyData})</h4>
          {FinancialCharts.renderLoansValuePerStaffChart(loanValuePerStaffData)}
        </div>

        {/* Loan Values per Branch Chart */}
        <div className="chart-item">
          <h4>Loan Values per Branch ({currencyData})</h4>
          {FinancialCharts.renderLoanValuesPerBranchChart(
            loanValuesPerBranchData
          )}
        </div>

        {/* Loans per Branch Chart */}
        <div className="chart-item">
          <h4>Loans per Branch</h4>
          {FinancialCharts.renderLoansPerBranchChart(loansPerBranchData)}
        </div>

        <div className="chart-item">
          <h4>Workflow Status</h4>
          {FinancialCharts.renderWorkflowStatusChart(workflowStatusData)}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
